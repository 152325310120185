import '../styles/About.css'
const About : React.FC = () =>{

    return(
       <div className='about-main-container'>
        <img className="about-img" src="/assets/Subject1.PNG" alt="About-Image" />

        <div className='about-text-container'>
            <div>
                <h1 className='about-title'>WHO AM I ?</h1>

                <div>
                <p>
                I am a passionate and motivated Software Engineer with a First Class Honors in BEng (Hons) in Software Engineering from the Informatics Institute of Technology (IIT), affiliated with the University of Westminster, London. 
                My technical expertise spans a range of web technologies, including React, Node.js, GraphQL, and both structured and unstructured databases. 
                I also hold a Certificate in Software Engineering from the National Institute of Business Management (NIBM).
                </p>
                <p>In addition to my web development skills, I have experience in iOS development using Swift programming and SwiftUI, as well as working with the MEARN stack and Java. 
                I thrive in collaborative environments, leveraging my strong communication and analytical skills to deliver impactful solutions.
                </p>
                <p>
                Driven by a passion for continuous learning and self-improvement, I am dedicated to enhancing my technical expertise both independently and within a team setting. My proactive approach ensures the timely delivery of projects, and I constantly seek opportunities for professional growth to make a meaningful impact in the technology sector.
                </p>
                </div>
            </div>

        </div>
       </div>
    );
};

export default About;